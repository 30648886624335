import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("periodos/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("periodos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    update(parametros) {
        return Vue.axios.put("periodos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("periodos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("periodos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    generar(parametros) {
        return Vue.axios.post("periodos/generacionAutomatica", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getPeriodoIncidencia(parametros) {
        return Vue.axios.get("periodo-incidencias/periodo", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getPeriodoI(parametros) {
        return Vue.axios.post("periodo-incidencias/periodo",  parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getNumeroPeriodo(parametros) {
        return Vue.axios.get("periodos/codigo", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateFiniquito(parametros) {
        return Vue.axios.put("periodos/updatePeriodos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}