<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay> 
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tablaCalculoNomina"
                        :tableName="'Nómina acumulada'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :showAdvancedFilters="true"
                        :perPage="[10,25,50,100]"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            fab
                                            color="#FFFFFF"
                                            small
                                            elevation="0"
                                            dark
                                            @click="actualizarTabla()"
                                        >
                                            <v-icon color="rgba(0, 0, 0, 0.54)">mdi-reload</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Actualizar tabla</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">  
                                <v-autocomplete
                                    outlined
                                    label="Clientes"
                                    :items="itemsClientes"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="cliente_value"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsEmpresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Tipo de nómina"
                                    :items="itemsTiposNominas"
                                    item-text="claveDescripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="tipo_nomina_id"
                                    class="pa-0 ma-0"
                                    id="tipoNominaID"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Periodo"
                                    :items="itemsPeriodos"
                                    item-text="numero"
                                    item-value="id"
                                    persistent-hint
                                    v-model="periodo_value"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Estatus"
                                    :items="itemsEstatus"
                                    item-text="nombre"
                                    item-value="valor"
                                    persistent-hint
                                    v-model="estatus_value"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="12" md="12">
                                <div class="RangoIngreso">
                                    <span>Rango de fechas</span>
                                </div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_inicio"
                                    label="Fecha inicio"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaInicio"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_final"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaFinal"
                                />
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.clave_tipo_nomina}} - {{item.descripcion}}</div></td>
                                <td>
                                    <div>
                                        <v-menu v-model="item.menu" buttom :close-on-content-click="false" class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones menu-width"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    color="transparent"
                                                    rounded
                                                    class="elevation-0 v-btn-periodo"
                                                    :ripple="false" 
                                                >
                                                    <span class="span-periodo">{{item.numero_periodo}}</span>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <div xs="1" sm="1" md="1" lg="1" class="v-btn-alineacion background-transparent" style="float: right;">
                                                    <v-btn
                                                        @click="item.menu = false"
                                                        small
                                                        icon
                                                        text
                                                        :ripple="false" 
                                                        class="v-btn-cerrar mr-2"
                                                    >
                                                        <v-icon class="icono-cerrar"></v-icon>
                                                    </v-btn>
                                                </div>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-subtitle>
                                                            <v-row class="pa-0 ma-0">
                                                                <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                                    <p class="informacionGeneralTitulo">Información del periodo</p>
                                                                </v-col> 
                                                                <v-col cols="12" xs="6" sm="6" md="6" class="py-0" :style="$vuetify.breakpoint.name != 'xs'? 'border-right:1px solid #EFF1FB;':''">
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Periodicidad:</p>
                                                                        <p class="informacionGeneralText">{{item.periodicidad ? item.periodicidad:"Información faltante*"}}</p>
                                                                    </v-col>
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Código:</p>
                                                                        <p class="informacionGeneralText">{{item.numero_periodo}}</p>
                                                                    </v-col>
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                                        <p class="informacionGeneralText text-wrap">{{item.nombre_empresa}}</p>
                                                                    </v-col>
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Año:</p>
                                                                        <p class="informacionGeneralText text-wrap">{{item.nombreAnio}}</p>
                                                                    </v-col>
                                                                </v-col>

                                                                <v-col cols="12" xs="6" sm="6" md="6" class="py-0">
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Fecha inicial:</p>
                                                                        <p class="informacionGeneralText">{{dateFormat(item.fecha_inicial, 'modal')}}</p>
                                                                    </v-col>
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Fecha final:</p>
                                                                        <p class="informacionGeneralText">{{dateFormat(item.fecha_final, 'modal')}}</p>
                                                                    </v-col>
                                                                    
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Mes de acumulación:</p>
                                                                        <p class="informacionGeneralText">{{item.mes_de_acomulacion}}</p>
                                                                    </v-col>
                                                                </v-col>
                                                            </v-row>                           
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </td>
                                <td><div>{{dateFormat(item.fecha_de_pago, 'tabla')}}</div></td>
                                <td>
                                    <div>
                                        <v-chip
                                            v-if="item.procesando_timbres"
                                            class="mx-0 px-0 pl-1 bordeVchip"
                                            :text-color="textColor(item.estatus)" 
                                            outlined
                                        >
                                            <v-icon left class="mr-0">
                                               autorenew
                                            </v-icon>
                                            En proceso
                                        </v-chip>
                                        <v-chip
                                            v-else
                                            class="mx-0 px-0"
                                            color="transparent"
                                            :text-color="textColor(item.estatus)" 
                                        >
                                            <template v-if="item.estatus == 'Timbrado'">
                                                <img
                                                    style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                    src="/static/icon/aprovado.png"
                                                    alt="Estatus"
                                                />
                                                Timbrado
                                            </template>
                                            <template v-else-if="item.estatus == 'Incompleta'">
                                                <img
                                                    style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                    src="/static/icon/cancelado.png"
                                                    alt="Estatus"
                                                />
                                                Incompleta
                                            </template>
                                            <template v-else-if="item.estatus == 'Cancelado'">
                                                <img
                                                    style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                    src="/static/icon/cancelado.png"
                                                    alt="Estatus"
                                                />
                                                Cancelado
                                            </template>
                                            <template v-else-if="item.estatus == 'Acumulado'">
                                                <img
                                                    style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                    src="/static/icon/pendiente.png"
                                                    alt="Estatus"
                                                />
                                                Acumulado
                                            </template>
                                        </v-chip>
                                    </div>
                                </td>
                                <td >
                                    <div class="d-flex flex-row justify-center">


                                        <!-- <v-tooltip bottom v-show="false">
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small>
                                                    <v-icon class="tamIconoBoton invertirColorBotones">mdi-file-pdf</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Lista de raya</span>
                                        </v-tooltip> -->

                                        <v-speed-dial
                                            :id="item.id"
                                            direction="left"
                                            :open-on-hover="true"
                                            transition="scale-transition"
                                            class="elevation-0"
                                        >
                                            <template v-slot:activator>
                                                <v-btn
                                                    :id="item.id"
                                                    color="#fdfcfc"
                                                    fab
                                                    small
                                                    elevation="0"
                                                    style="z-index:999"
                                                >
                                                    <v-hover v-slot="{ hover }">
                                                        <v-icon
                                                            v-text="'$get_app_outline'"
                                                            :style="{ 'color': hover ? '#00C7FF' : '#828282' }"
                                                        ></v-icon>
                                                    </v-hover>
                                                </v-btn>
                                            </template>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        fab
                                                        small
                                                        color="#FFFFFF"
                                                        elevation="0"
                                                        @click="listaRaya(item)"
                                                    >
                                                        <v-icon color="red">mdi-file-pdf</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Lista de raya</span>
                                            </v-tooltip>
                                        </v-speed-dial>

                                        <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon 
                                                    fab
                                                    dark
                                                    small
                                                    class="elevation-0"
                                                >
                                                    <template  v-for="(item,valor) in attrs" >
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <template v-if="!item.procesando_timbres">
                                                    <v-list-item
                                                        @click="(item.tipo_periodo_especial == 'AGUINALDO' || item.tipo_periodo_especial == 'FINIQUITO') ? reporteAguinaldoFiniquito(item) : descargarReporte(item, 'reporte')"
                                                    >
                                                        <v-list-item-title>Reporte de empleados</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                                
                                                
                                                <v-list-item
                                                    v-if="item.timbrada"
                                                    @click="abrirModalCancelar(item)"
                                                >
                                                    <v-list-item-title>Cancelar timbrado de nómina</v-list-item-title>
                                                </v-list-item>


                                                <v-list-item
                                                    v-if="!item.timbrada"
                                                    @click="detalleTimbre(item)"
                                                >
                                                    <v-list-item-title>Detalle del periodo</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="item.timbrada"
                                                    @click="detalleTimbre(item)"
                                                >
                                                    <v-list-item-title>Detalle del periodo</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-else-if="!item.procesando_timbres"
                                                    @click="timbrarNomina(item)"
                                                >
                                                    <v-list-item-title>Timbrar nómina</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="rol == 'root' || datosLogin.cliente.odesa == true"
                                                    @click="odessaConfirmacion(item)"
                                                >
                                                    <v-list-item-title>ODESSA confirmación</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    @click="desacumular(item)"
                                                >
                                                    <v-list-item-title>Desacumular nómina</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>

                                        <v-tooltip top v-if="item.requiere_retimbrar">
                                            <template v-slot:activator="{ on }">
                                                <v-img
                                                    v-on="on"
                                                    class="mr-n8 mt-2 ml-3"
                                                    style="margin-bottom:2px"
                                                    max-height="23"
                                                    max-width="23"
                                                    src="/static/svg/signoExclamacion.svg"
                                                ></v-img>
                                            </template>
                                            <span>Es necesario retimbrar.</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialogDownload" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalDownload"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="justify-center">
                            <div class="headerModal">
                                <img :src="imagenDownload" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModalDownload }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text>
                            <div class="loading">
                                <lottie :options="defaultOptions" :height="150" :width="150"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>


            <template class="borde-card">
                <v-dialog v-model="dialogCancelar" persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
                            <div class="headerModal-filter">
                                <h2 class="titleModalBuscar">{{ modalTitle }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <v-container grid-list-md id="contenedor">
                                <v-row class="px-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <v-radio-group
                                            v-model="claveMotivo"
                                            mandatory
                                        >
                                            <v-radio
                                                label="Clave: 02: Comprobante emitido con errores sin relación."
                                                value="02"
                                                class="radioLabel"
                                            ></v-radio>
                                            <v-radio
                                                label="Clave 03: No se llevó a cabo la operación"
                                                value="03"
                                                class="radioLabel"
                                            ></v-radio>
                                            <p v-if="claveMotivo=='03'" class="pNota mb-0">Nota: Una vez aplicado el motivo de cancelación con clave "03", 
                                                el sistema no permitirá realizar ningún timbre, ya que este supuesto 
                                                aplica cuando se facturó una operación que no se concreta.
                                            </p>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cerrar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="cancelarTimbreNomina()"
                            >
                                Enviar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>



            <template>
                <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                                <v-btn
                                    @click="cerrarModalErrores()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                    absolute
                                    top
                                    right
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            <div class="headerModalStart">
                                <h2 class="titleModalErrores">Timbres requeridos</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-row class="pa-0 ma-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        <table class="tbl-errores">
                                            <thead class="tbl-header">
                                                <tr>
                                                    <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Registro patronal</th>
                                                    <th class="errorth">Requeridos</th>
                                                    <th style="border-top-right-radius: 20px;" class="errorth">Disponibles</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="erroresRP.length == 0" >
                                                    <td class="emptyTableErrores" colspan="3">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else v-for="(error, index) in erroresRP">
                                                    <template>
                                                        <tr :key="index">
                                                            <td class="pl-7 errortd pt-4 pb-0">
                                                                <div class="textoTablaError01">{{error.registro_patronal}}</div>
                                                            </td>
                                                            <td class="errortd"> <div class="textoTablaError01">{{error.requeridos}}</div></td>
                                                            <td class="errortd"> <div class="textoTablaError03">{{error.disponibles}}</div> </td>
                                                        </tr>
                                                        
                                                        <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import Axios from 'axios';
import Datatable from '@/components/datatable/Datatable';
import queries from '@/queries/CalculoNomina';
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";
import apiClientes from "@/api/clientes";
import apiTipoNomina from '@/api/nominas/tipoNomina';
import apiPeriodos from '@/api/nominas/periodos';
import apiEmpresas from '@/api/empresas'
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import Lottie from 'vue-lottie';
import animationData from '@/animations/cliker.json';
import LoadingCliker from '@/components/LoadingCliker';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';


export default {
    components: {
        'data-table': Datatable,
        'lottie': Lottie,
        'loading-cliker':LoadingCliker,
        VuetifyDatePicker,
    },
    data() {
        return {
            datosLogin      : null,
            rol             : null,
            urlImg          : null,
            url             : "calculo-nomina/listado",
            columns         : [
                {
                    label: 'Tipo nómina',
                    name: 'clave',
                    filterable: false
                },
                {
                    label: 'Periodo',
                    name: 'numero',
                    filterable: false
                },
                {
                    label: 'Fecha de pago',
                    name: 'fecha_de_pago',
                    filterable: false
                },
                {
                    label: 'Estatus',
                    name: 'estatus',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {
                activo    :true,
                paginate  :true,
                order     :"desc",
                cliente_id: null,
                empresa_id: null,
                acumulado : true,
                desacumulado: false
            },
            tableData       : {},
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            calculoNom                      : {
                id                          : null,
                tipo_nomina_id              : null,
                periodo_id                  : null,
                incidencias_checador        : false,
                incidencias_capturadas      : false,
                modificaciones_adicionales  : false,
                acumulado                   : false,
                modificaciones_campos       : {
                    empresa_id              : null,
                    comision                : 0,
                    iva                     : 0,
                    aplica_retencion_iva    : false,
                    aplica_retencion_isr    : false,
                },
            },
            userData                : this.$session.get('usuario'),
            accion                  : null,
            tituloModal             : null,
            dialog                  : false,
            isLoading               : false,
            isSaving                : false,
            imagen                  : '',
            items_empleados         : [],
            modelo_empleados        : [],
            itemsTiposNominas       : [],
            tipo_nomina_id          : null,
            itemsPeriodos           : [],
            calendar_pago           : false,
            incidencias_reloj_checador  : "NO",
            incidencias_capturadas      : "NO",
            modificaciones_adicionales  : "NO",
            empresa_id              : null,
            itemsEmpresas           : [],
            comision                : 0,
            iva                     : 0,
            retencion_iva           : "NO",
            retencion_isr           : "NO",
            itemsClientes           : [],
            cliente_value           : null,
            empresa_value           : null,
            periodo_value           : null,
            fecha_inicio          : null,
            fecha_final           : null,
            fechaPago               : null,
            cambio_empleado         : false,
            imagenAvatar            : "/static/avatar/usuario.jpg",
            showGuardar             : true,
            isLoadingCalculo        : true,
            dialogCalculo           : false,
            imagenCalculo           : '',
            tituloModalCalculo      : 'Cálculo de nómina',
            itemCalculo             : null,
            dialogDownload          : false,
            imagenDownload          : "/static/icon/catalogos.png",
            tituloModalDownload     : "Descargando archivo",
            defaultOptions          : { animationData: animationData },
            loadingLottie           : false,
            fraseLoading            : 'Timbrando',
            estatus_value           : null,
            itemsEstatus            : [
                { id:1, nombre:"Acumulado", valor:"ACUMULADO" },
                { id:1, nombre:"Cancelado", valor:"CANCELADO" },
                { id:1, nombre:"Incompleta", valor:"INCOMPLETO" },
                { id:1, nombre:"Timbrado", valor:"TIMBRADO" }
            ],
            dialogCancelar : false,
            modalTitle : '',
            datos : null,
            claveMotivo: '02',
            menuPeriodo: false,
            dialogErrores: false,
            erroresRP: []
        }
    },
    watch: {
        cliente_value: function(val){
            if(val!= null){
               this.getEmpresas(val);
            }
        },
        empresa_value: function(val){
            if(val != null){
                this.getTiposNominas(); 
            }
        },
        tipo_nomina_id: function(val){
            if(val != null){
                this.getPeriodos(val);
                //this.getEmpleados(val);
            } 
        },
        modificaciones_adicionales: function(val){
            if(val == 'NO'){
                document.getElementById("tipoNominaID").focus();
            }
        }
    },
    methods: {
        cerrarModal() {
            this.dialog         = false;
            this.isSaving       = false;
            this.dialogCalculo  =  false;
            this.dialogCancelar =  false;
            this.claveMotivo    = '02'
            this.resetValues();
        },
        resetValues() {
            this.calculoNom                 = {
                id                          : null,
                tipo_nomina_id              : null,
                periodo_id                  : null,
                incidencias_checador        : false,
                incidencias_capturadas      : false,
                modificaciones_adicionales  : false,
                acumulado                   : false,
                modificaciones_campos       : {
                    empresa_id              : null,
                    comision                : 0,
                    iva                     : 0,
                    aplica_retencion_iva    : false,
                    aplica_retencion_isr    : false,
                },
            },
            this.incidencias_reloj_checador  = "NO";
            this.incidencias_capturadas      = "NO";
            this.modificaciones_adicionales  = "NO";

            this.empresa_id         = null;
            this.cliente_value      = null;
            this.empresa_value      = null;
            this.itemsEmpresas      = [];
            this.comision           = 0;
            this.iva                = 0;
            this.retencion_iva      = "NO";
            this.retencion_isr      = "NO"; 
            this.fechaPago          = null;
            this.itemsClientes      = [];
            this.tipo_nomina_id     = null;
            this.items_empleados    = [];
            this.modelo_empleados   = [];
            this.itemsTiposNominas  = [];
            this.itemsPeriodos      = [];
            this.showGuardar        = true;
            this.estatus_value      = null;

            this.cambio_empleado = false;
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        acumular(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas acumular el cálculo de nómina? No se podrá realizar ningún movimiento posteriormente.",
                "Acumular",
                () => {
                    let param = {nomina_id:data.id};
                    apiCalculoNomina.acumular(param).then(response => {
                        Notify.Success("Cálculo de nómina acumulado", "El cálculo de nómina ha sido acumulado satisfactoriamente.");
                        this.$refs.tablaCalculoNomina.getData();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
       
        async loadModalData() {
            await this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            } else {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },
        setFilters() {
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let tipo_nomina = this.tipo_nomina_id;
            let periodo_value = this.periodo_value;
            let fecha_inicio = this.fecha_inicio;
            let fecha_final = this.fecha_final;
            let estatus     = this.estatus_value;
            

            if (this.rol !== "root") {
                cliente = this.datosLogin.cliente_id;
                if (this.rol !== "admin") {
                    empresa = this.datosLogin.empresa_id;
                } 
            } 

            let filterParams = {
                cliente_id: cliente,
                empresa_id: empresa,
                tipo_nomina_id: tipo_nomina,
                periodo_id: periodo_value,
                min_fecha_inicio: fecha_inicio,
                max_fecha_final: fecha_final,
                estatus:estatus
            };

            let defaultParams = {
                activo    :true,
                paginate  :true,
                order     :"desc",
                acumulado : true,
                desacumulado: false
            };

            if (filterParams != null) Object.assign(defaultParams, filterParams);

            this.filters = defaultParams;
            this.cliente_value = null;
            this.itemsEmpresas = [];
            this.empresa_value = null;
            this.itemsTiposNominas = [];
            this.tipo_nomina_id = null;
            this.itemsPeriodos = [];
            this.periodo_value = null;
            this.estatus_value = null;
            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
        },
        async getClientes(){
            let paramClientes = {
                activo      : true, 
                paginate    : false,
                cliente_id  : this.datosLogin.cliente_id
            }
            await apiClientes.getClientes(paramClientes).then((response) => {
                this.itemsClientes = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los clientes");
            })
        },
        async getTiposNominas() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value
            }
            this.itemsTiposNominas = [];
            await apiTipoNomina.find(param).then((response) => {
                let format = response.data;
                if(format.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.itemsTiposNominas.push(item);
                    })
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },
        async getPeriodos(tipoNomID){
            
            let param = {
                activo      : true,
                paginate    : false,
                tipo_nomina_id : tipoNomID,
                acumulado   : true
            }
            await apiPeriodos.find(param).then((response) => {
                this.itemsPeriodos = response.data;
                if(this.itemsPeriodos.length != 0){
                    if(this.accion === "add") {
                        let per = this.itemsPeriodos.find( elemento => elemento.actual == true )
                        this.calculoNom.periodo_id = per.id;
                        this.fechaPago = this.dateFormat(per.fecha_de_pago, 'modal');
                    }                    
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los periodos");
            })
        },
        async getEmpleados(id) {
            let param = {tipo_nomina_id:id, tipo: 'GENERAL'}
            await apiTipoNomina.getEmpleados(param).then((response) => {
                this.items_empleados = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los empleados.");
            })
        },
        remove (item) {
            const index = this.modelo_empleados.indexOf(item.id)
            if (index >= 0) this.modelo_empleados.splice(index, 1)
        },
        cambio_emp(){
            this.cambio_empleado = true;
        },
        async getEmpresas(cliente_id){
            let param = {
                activo      : true, 
                paginate    : false,
                cliente_id  : cliente_id
            }
            await apiEmpresas.getEmpresas(param).then((response) => {
                this.itemsEmpresas = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar las empresas.");
            })
        },
        async descargarReporte(item, tipo){
            let url             = "reportes/nomina";
            this.loadingLottie  =  true;
            this.fraseLoading   = "Descargando";
            const FileDownload  = require("js-file-download");
            let nombre          = item.nombre_empresa + "-" + item.clave_tipo_nomina + "-" + item.numero_periodo + ".xlsx";
            let parametros = { nomina_id : item.id, tipo_nomina : 'GENERAL' }
            const object1 = parametros;

            try {
                await Vue.axios({
                method: "POST",
                responseType: "blob",
                url: url,
                data: object1,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                });
            } catch (err) {
                this.loadingLottie = false;
                Notify.ErrorToast("No se pudo calcular la nomina");
            }
        },
        reporteAguinaldoFiniquito(item){
            let sefl = this;
            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const FileDownload = require("js-file-download");

            
            let parametros = {
                nomina_id : item.id,
                tipo_nomina: item.tipo_periodo_especial
            }

            let nombre;
            nombre = item.nombre_empresa + "-" + item.clave_tipo_nomina + "-" + item.numero_periodo + ".xlsx";

            let urlCalculo = "";

            if(item.tipo_periodo_especial == 'AGUINALDO'){
                urlCalculo = "reportes/nomina"
            }
            else if(item.tipo_periodo_especial == 'FINIQUITO'){
                urlCalculo = "reportes/nomina"
            }
            try {
                Vue.axios({
                method: "POST",
                responseType: "blob",
                url: urlCalculo,
                data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(async error =>{
                    //console.log(err);
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar el cálculo");
                console.log(err);
                this.loadingLottie = false;
            }
        },
        dateFormat(fecha, tipo) {
            if(!fecha) return
            let fecha2 = fecha.split("-");
            if(tipo == 'modal'){
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }else{
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
        },
        timbrarNomina(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas timbrar el cálculo de nómina " + data.clave_tipo_nomina + " - " + data.descripcion + "?",
                "Timbrar",
                () => {
                    /* this.fraseLoading = "Timbrando";
                    this.loadingLottie = true; */
                    let param = {nomina_id:data.id};
                    apiCalculoNomina.timbrar(param).then(response => {
                        //this.detalleTimbre(data);

                        if(response.status == 200){
                            this.detalleTimbre(data);
                        }
                        else if(response.status == 202){
                            this.erroresRP = response.data.items;
                            this.dialogErrores = true;
                        }
                    })
                    .catch(err => {
                        /* this.loadingLottie=false;  */
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                        return;
                    });

                }
            )
        },
        abrirModalCancelar(data){
            this.dialogCancelar = true;
            this.modalTitle = 'Seleccionar motivo de cancelación';
            this.datos = data;
        },
        cancelarTimbreNomina(){
            Notify.Alert(
                "¿Estás seguro que deseas cancelar el timbre de este cálculo de nómina?",
                "",
                "Cancelar timbrado",
                () => {
                    this.fraseLoading = "Cancelando";
                    this.loadingLottie = true;
                    let param = {
                        clave_motivo:this.claveMotivo,
                        nomina_id:this.datos.id
                    };
                    apiCalculoNomina.cancelar(param).then(response => {
                        this.loadingLottie=false; 
                        
                        if(response.status === 200){
                            Notify.Success("Cancelado de nómina", "El cálculo de nómina ha sido cancelado correctamente.");
                        }
                        else if(response.status == 202){
                            this.erroresRP = response.data.items;
                            this.dialogErrores = true;
                        }
                        this.cerrarModal();
                        this.$refs.tablaCalculoNomina.getData();
                    })
                    .catch(err => {
                        this.loadingLottie=false; 
                        this.cerrarModal();
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
        detalleTimbre(data){
            this.$session.remove("detalleTimbre");
            this.$session.set('detalleTimbre',data);
            this.$router.push('/detalleTimbre');
        },
        textColor(estatus){
             switch (estatus) {
                case "Incompleta":
                    return "#F86868";
                    break;
                case "Cancelado":
                    return "#F86868";
                    break;
                case "Acumulado":
                    return "#FF9B05";
                    break;
                case "Timbrado":
                    return "#86EF83";
                    break;
                case undefined:
                    return "";
                    break;
                default:
                    return "#BDBDBD";
                    break;
            }
        },
        detalleAcumulado(data){
            this.$session.remove("detalleAcumulado");
            this.$session.set('detalleAcumulado',data);
            this.$router.push('/detalleAcumulado');
        },
        async desacumular(data){
            let param = {};
            param = { 
                nomina_id:data.id, 
                empleados:[],
            };
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas realizar el proceso de desacumulación?",
                "Desacumular",
                () => {
                    apiCalculoNomina.desacumular(param).then(response => {
                        Notify.Success("Operación exitosa", "La desacumulación se ha realizado satisfactoriamente.");
                        this.$refs.tablaCalculoNomina.getData();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        listaRaya(item){
            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const FileDownload = require("js-file-download");

            let parametros = {
                nomina_id: item.id
            }

            let nombre;
            nombre = "Lista_de_raya_"+item.nombre_empresa + "_" + item.clave_tipo_nomina + "_" + item.numero_periodo + ".pdf";

            let urlCalculo = "reportes/lista-raya";

            try {
                Vue.axios({
                method: "POST",
                responseType: "blob",
                url: urlCalculo,
                data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(async (error) => {
                    this.loadingLottie = false;

                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar el cálculo");
                console.log(err);
                this.loadingLottie = false;
            }
        },
        actualizarTabla(){
            this.$refs.tablaCalculoNomina.getData();
        },

        odessaConfirmacion(item){
            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const FileDownload = require("js-file-download");

            let parametros = {
                periodo_id: item.periodo_id
            }
            let nombre;
            nombre = "ODESSA_confirmacion"+item.nombre_empresa + "_" + item.clave_tipo_nomina + "_" + item.numero_periodo + ".zip";

            let urlCalculo = "odesa/confirmacion";

            try {
                Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: urlCalculo,
                    data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(async (error)=>{
                    console.log(error);
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                    
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar la descarga");
                console.log(err);
                this.loadingLottie = false;
            }
        },

        cerrarModalErrores(){
            this.dialogErrores = false;
            this.erroresRP = [];
        }
        
    },
    created(){
        this.urlImg = GetUrlImg.getURL();
        this.datosLogin = this.$session.get('usuario');
        this.rol = this.datosLogin.rol.name;
        if (this.rol != "root") {
            this.filters.cliente_id = this.datosLogin.cliente_id;
            this.cliente_value = this.datosLogin.cliente_id;
            if(this.rol == 'admin-empresa' || this.rol == 'admin-sucusal' || this.rol == 'admin-depto'){
                this.filters.empresa_id =  this.datosLogin.empresa_id;
            }
        }
        if(this.rol == 'root'){
            this.getClientes();
        }
    },
    updated() {
        document.querySelector('#tableScroll thead tr th:nth-child(5)').style.width         = "310px";
        document.querySelector('#tableScroll thead tr th:nth-child(5)').style.paddingRight  = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(5)').style.paddingLeft   = "8px";
    },

}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .menuClassAcciones{
        border-radius: 10px!important;
    }
    .bordeVchip.v-chip.v-chip--outlined{
        border-width: none;
        border-style: none;
    }
    .titleModalBuscar {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #1E2245;
        padding-bottom: 25px;
    }
    .radioLabel{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .pNota{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #D93025;
    }
    .item-subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #1E2245;
        margin-bottom:0px;
    }
    .item-subtitle-text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .v-btn-periodo:hover:before {
      background-color: transparent;
      
    }
    .v-btn-periodo:before {
        display: none;
    }
    .v-btn-cerrar:hover:before {
      background-color: transparent;
      
    }
    .v-btn-cerrar:before {
        display: none;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
    .menu-width{
        max-width: 500px;
        border-radius: 20px !important;
    }
    .span-periodo{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        color: #1E2245;
        text-decoration-line: underline;
    }

    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }

    #btnModalErrores{
        display: none;
    }
</style>
